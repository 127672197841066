import { RouteDef } from 'app/hooks/useRenderRoutes';
import { DetailModeEnum } from 'core/containers/FormDetailPage';
import safeLazy from 'core/functions/safeLazy';

import { financeEntities } from './module';

export const financeRoutes: RouteDef[] = [
  {
    path: financeEntities.cost.urls().list,
    component: safeLazy(() => import('./pages/CostListPage')),
    layout: 'side',
  },
  {
    path: financeEntities.cost.urls().detail(':id'),
    component: safeLazy(() => import('./pages/CostDetailPage')),
    layout: 'side',
    mode: DetailModeEnum.EDIT,
    exact: false,
  },
  {
    path: financeEntities.cost.urls().create,
    component: safeLazy(() => import('./pages/CostDetailPage')),
    layout: 'side',
    mode: DetailModeEnum.CREATE,
    exact: false,
  },
  {
    path: financeEntities.cost.urls().clone(':id'),
    component: safeLazy(() => import('./pages/CostDetailPage')),
    layout: 'side',
    mode: DetailModeEnum.CLONE,
    exact: false,
  },
  {
    path: financeEntities.businessPlan.urls().overview(':id'),
    component: safeLazy(() => import('./pages/BusinessPlanListPage')),
    layout: 'side',
    exact: false,
  },
  {
    path: financeEntities.invoice.urls().list,
    component: safeLazy(() => import('./pages/InvoiceListPage')),
    layout: 'side',
  },
  {
    path: financeEntities.invoice.urls().detail(':id'),
    component: safeLazy(() => import('./pages/InvoiceDetailPage')),
    layout: 'side',
    mode: DetailModeEnum.EDIT,
    exact: false,
  },
  {
    path: financeEntities.invoice.urls().create,
    component: safeLazy(() => import('./pages/InvoiceDetailPage')),
    layout: 'side',
    mode: DetailModeEnum.CREATE,
    exact: false,
  },
  {
    path: financeEntities.invoice.urls().clone(':id'),
    component: safeLazy(() => import('./pages/InvoiceDetailPage')),
    layout: 'side',
    mode: DetailModeEnum.CLONE,
    exact: false,
  },
];
