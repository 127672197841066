/* eslint-disable @typescript-eslint/no-unused-vars */
import { definition, EntityMeta, getStandardApi, getStandardUrls, T } from 'core/module';

import { CostCreate, CostDetail, CostGrid } from './models/Cost';
import { InvoiceCreate, InvoiceDetail, InvoiceGrid } from './models/Invoice';

export const financeEntities = {
  cost: {
    ...definition<CostGrid, CostDetail, CostCreate>('costs', {
      name: (t: T) => t('Costs'),
      listName: (t: T) => t('Costs'),
    }),
    urls: (_?: string) => ({
      ...getStandardUrls('costs'),
      projects: '/costs/projects',
    }),
    api: () => ({
      ...getStandardApi('costs'),
      clusters: (id?: string | number) => (id ? `/costs/clusters/${id}` : '/costs/clusters'),
      categories: (id?: string | number) => (id ? `/costs/categories/${id}` : '/costs/categories'),
    }),
  },
  businessPlan: {
    ...definition<CostGrid, CostDetail>('costs', {
      name: (t: T) => t('Costs'),
      listName: (t: T) => t('Costs'),
      permission: 'costs',
    }),
    urls: (_?: string) => ({
      ...getStandardUrls('business-plans'),
      overview: (id?: number | string) => `/business-plans/${id}`,
      detail: (id: number | string) => `/business-plans/${id}/detail`,
      balanceSheet: (id: number | string) => `/business-plans/${id}/balanceSheet`,
      bankPortfolio: (id: number | string) => `/business-plans/${id}/bankPortfolio`,
      settings: (id: number | string) => `/business-plans/${id}/settings`,
    }),
    api: () => ({
      ...getStandardApi('business-plans'),
      reduction: (id: string) => `/business-plans/reductions/${id}`,
      totalRevenue: (id: string) => `/business-plans/revenue/total/${id}`,
      totalRevenueDetail: (id: string) => `/business-plans/revenue/total/${id}/detail`, // Total Revenue Details for datagrid
      forecastedRevenue: (id: string) => `/business-plans/revenue/forecasted/${id}`,
      forecastedRevenueDetail: (id: string) => `/business-plans/revenue/forecasted/${id}/detail`, // Forecasted Revenue Details for datagrid
      eat: (id: string) => `/business-plans/revenue/eat/${id}`,
      totalCosts: (id: string) => `/business-plans/costs/total/${id}`,
      totalCostsDetail: (id: string) => `/business-plans/costs/total/${id}/detail`, // Total Costs Details for datagrid
      cpww: (id: string) => `/business-plans/costs/cpww/${id}`, // Costs per Working Week
      cpwwDetail: (id: string) => (id: string) => `/business-plans/costs/total/${id}/detail`, // Costs per Working Week Details for datagrid, uses same data as total costs details
      cmavg: (id: string) => `/business-plans/costs/cmavg/${id}`, // Costs moving average
      netpm: (id: string) => `/business-plans/revenue/netpm/${id}`, // Net Profit Margin
      balanceSheet: (id: string) => `/business-plans/balance-sheets/${id}`,
      overview: (id: string) => `/business-plans/overview/${id}`,
    }),
  },
  invoice: {
    ...definition<InvoiceGrid, InvoiceDetail, InvoiceCreate>('invoices', {
      name: (t: T) => t('Invoices'),
      listName: (t: T) => t('Invoices'),
    }),
    urls: (_?: string) => ({
      ...getStandardUrls('invoices'),
    }),
    api: () => ({
      ...getStandardApi('invoices'),
    }),
  },
} as const;

// REQUIRED !!!
// @ts-ignore
const verifyEntities: Record<keyof typeof financeEntities, EntityMeta> = financeEntities;

export type K = keyof typeof financeEntities;
